<template>
    <div class="live-ing-center">
        <div id="container" v-if="liveState == 1 || liveState == 3">
            <div class="top-box">
                <div class="left-bag-coupon">
                    <div class="item bag-box" v-if="currentBag" @click="openBag">
                        <div class="item-container">
                            <img class="icon" src="~assets/image/student/live/luckyBag.png" alt="福袋" title="福袋" />
                        </div>
                        <div class="countdown">{{ currentBag.newCountdown | bagCountdown }}</div>
                    </div>
                    <div class="item coupon-box" v-if="addedCoupon.length > 0" @click="openCoupon">
                        <div class="item-container">
                            <img class="icon" src="~assets/image/student/live/coupon.png" alt="优惠券" title="优惠券" />
                        </div>
                        <div class="corner-mark">{{ addedCoupon.length }}</div>
                    </div>
                </div>
                <div class="right-goods-info" v-if="goodsInfo">
                    <div class="paster">
                        <img class="paster-img" :src="goodsInfo.paster" :alt="goodsInfo.goods_name" title="goodsInfo.goods_name" />
                    </div>
                </div>
            </div>
            <div class="bottom-box">
                <div class="left-bullet-chat">
                    <el-scrollbar style="height: 100%">
                        <!-- <div class="item" v-for="(item, index) in bulletChatList" :key="item.id"> -->
                        <div class="bullet-chat-list">
                            <div class="item" v-for="(item, index) in bulletChatList" :key="index">
                                <!-- <div class="item" v-for="(item, index) in 9" :key="index"> -->
                                <span class="username">{{ item.nickname }}：</span>
                                <span class="content">{{ item.barrage }}</span>
                            </div>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="right-goods-popup" v-if="goodsInfo">
                    <div class="img-box">
                        <img
                            class="goods-img"
                            :src="goodsInfo.goods_master_img"
                            :alt="goodsInfo.goods_name"
                            :title="goodsInfo.goods_name"
                        />
                    </div>
                    <div class="goods-info">
                        <div class="title">{{ goodsInfo.goods_name }}骄傲老实交代拉克丝记得拉谁看得见爱丽丝肯德基</div>
                        <div class="coupon" v-if="coupon">
                            <div class="left-coupon-info">
                                <span class="text">满</span>
                                <span class="number">{{ parseInt(coupon.coupon_full) }}</span>
                                <template v-if="coupon.couponType == 1">
                                    <span class="text">减</span>
                                    <span class="number">{{ parseInt(coupon.coupon_minus) }}</span>
                                </template>
                                <template v-else>
                                    <span class="text">打</span>
                                    <span class="number">{{ parseInt(coupon.coupon_minus) }}</span>
                                    <span class="text">折</span>
                                </template>
                            </div>
                            <div class="decorate"></div>
                            <div class="right-action">抢</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LiveingCenter",
    data() {
        return {};
    },
    props: {
        goods: {
            type: Object,
            default: () => {},
        },
        couponList: {
            type: Array,
            default: () => [],
        },
        addedCoupon: {
            type: Array,
            default: () => [],
        },
        currentBag: {
            type: Object,
            default: () => null,
        },
        bulletChatList: {
            type: Array,
            default: () => [],
        },
        liveState: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        goodsInfo() {
            return this.goods && Object.keys(this.goods).length > 0 ? this.goods.goods_info : null;
        },
        richText() {
            return this.goodsInfo ? this.HTMLDecode(this.goodsInfo.goods_body) : "";
        },
        coupon() {
            if (!this.goodsInfo || this.couponList.length <= 0) return null;
            const goodsCoupon = this.couponList.find((item) => item.status != 1 && item.goods_id == this.goods.goods_id);
            return goodsCoupon;
        },
    },
    components: {},
    methods: {
        openCoupon() {
            this.$emit("openCoupon");
        },
        openBag() {
            this.$emit("openBag");
        },
        HTMLDecode(text) {
            let temp = document.createElement("div");
            temp.innerHTML = text;
            let output = temp.innerText || temp.textContent;
            temp = null;
            return output;
        },
    },
    filters: {
        bagCountdown(time) {
            const value = time * 1;
            const times = [];
            const getValue = (value) => ((value + "").length <= 1 ? `0${value}` : value);
            let minute = getValue(Math.floor(value / 60) % 60);
            let second = getValue(value % 60);
            return [minute, second].join(":");
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.live-ing-center {
    box-sizing: border-box;
    flex: 1;
    position: relative;
    padding-top: 10px;
    padding-bottom: 30px;
    #container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        .top-box {
            display: flex;
            .left-bag-coupon {
                display: flex;
                justify-content: space-between;
                width: 130px;
                .item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border-radius: 14px;
                    background-color: rgba(0, 0, 0, 0.23);
                    cursor: pointer;
                    .icon {
                        width: 100%;
                        height: 100%;
                        transform: rotate(35deg) translate(0, 9px);
                    }
                    .item-container {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }
                }
                .bag-box {
                    .countdown {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 50px;
                        height: 20px;
                        font-size: 12px;
                        color: #fff;
                        border-radius: 10px;
                        background-color: rgba(0, 0, 0, 0.5);
                        opacity: 0.9;
                    }
                }
                .coupon-box {
                    .corner-mark {
                        position: absolute;
                        top: 0;
                        right: 0;
                        transform: translate(40%, -40%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        height: 20px;
                        font-size: 14px;
                        color: #d50715;
                        background: #fece0a;
                        box-shadow: 0px 4px 7px 0px rgba(94, 57, 1, 0.3);
                        border-radius: 50%;
                    }
                }
            }
            .right-goods-info {
                flex: 1;
                box-sizing: border-box;
                display: flex;
                height: 140px;
                margin-left: 40px;
                .paster {
                    flex: 1;
                    margin-right: 15px;
                    text-align: end;
                    .paster-img {
                        max-height: 150px;
                    }
                }
            }
        }
        .bottom-box {
            display: flex;
            justify-content: space-between;
            height: 265px;
            /* overflow: hidden; */
            .left-bullet-chat {
                width: 50%;
                height: 100%;
                overflow: hidden;
                .bullet-chat-list {
                    .item {
                        width: fit-content;
                        margin-bottom: 10px;
                        padding: 5px 10px;
                        word-break: break-all;
                        font-size: 16px;
                        color: #ff3e6c;
                        border-radius: 15px;
                        background-color: rgba(0, 0, 0, 0.3);
                        .content {
                            margin-left: 9px;
                            color: #fff;
                        }
                    }
                    .item:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .right-goods-popup {
                box-sizing: border-box;
                align-self: flex-end;
                width: 174px;
                height: fit-content;
                background-color: #fff;
                border-radius: 6px;
                .img-box {
                    width: 170px;
                    height: 170px;
                    padding: 2px 2px 0;
                    overflow: hidden;
                    .goods-img {
                        width: 100%;
                        height: 100%;
                        vertical-align: middle;
                        border-radius: 6px 6px 0 0;
                    }
                }
                .goods-info {
                    margin: 7px 5px;
                    overflow: hidden;
                    .title {
                        box-sizing: border-box;
                        font-size: 14px;
                        line-height: 18px;
                        color: #222;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2; /*几行就写数字几*/
                        text-overflow: ellipsis;
                    }
                    .coupon {
                        display: flex;
                        width: 100%;
                        height: 42px;
                        margin-top: 5px;
                        color: #fff;
                        background-image: linear-gradient(to right, #f41c4f, #fd6f6c);
                        overflow: hidden;
                        .left-coupon-info {
                            flex: 1;
                            align-self: center;
                            text-align: center;
                            font-size: 14px;
                            .number {
                                font-size: 20px;
                            }
                        }
                        .decorate {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            width: 8px;
                            height: 100%;
                            &::before,
                            &::after {
                                content: "";
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                background-color: #fff;
                                transform: translate(0, -50%);
                            }
                            &::after {
                                transform: translate(0, 50%);
                            }
                        }
                        .right-action {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 38px;
                            height: 100%;
                            font-size: 18px;
                            font-style: italic;
                        }
                    }
                }
            }
        }
    }
}

::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
::v-deep .el-scrollbar__thumb {
    display: none;
}
</style>
