<template>
    <div class="coupon">
        <div class="container">
            <my-popup
                :is-show="isShow"
                title="优惠券"
                :left-icon="leftIcon"
                @leftHandler="closeCouponPopup"
                z-index="99"
                @animateEnd="animateEnd"
            >
                <div class="content">
                    <template v-for="item in list">
                        <div class="coupon-item" :key="item.id">
                            <div class="left-coupon-size">
                                <div class="discount" v-if="item.couponType == 2">
                                    <div class="num">
                                        <span>{{ parseInt(item.coupon_minus) }}</span>
                                        <span class="decorate">折</span>
                                    </div>
                                    <div class="amount">满{{ parseInt(item.coupon_full) }}元可用</div>
                                </div>
                                <div class="full-reduction" v-else>
                                    <div class="num">
                                        <span class="decorate">￥</span>
                                        <span>{{ parseInt(item.coupon_minus) }}</span>
                                    </div>
                                    <div class="amount">满{{ parseInt(item.coupon_full) }}元可用</div>
                                </div>
                            </div>
                            <div class="center-info">
                                <div class="title-box">
                                    <div class="decorate">商品券</div>
                                    <div class="title">{{ item.couponName }}</div>
                                </div>
                                <div class="desc-info">
                                    <div class="info-item">仅限指定商品组合使用</div>
                                    <div class="info-item">每人限领{{ item.everyoneCount }}张</div>
                                </div>
                            </div>
                            <div class="right-action" v-if="item.status == 0" @click="release(item)">
                                <div class="mask"></div>
                                <div class="wavy-line">
                                    <div class="circle-item" v-for="(dotItem, index) in 8" :key="index"></div>
                                </div>
                                <div class="row-item one-row">立即</div>
                                <div class="row-item two-row">发券</div>
                            </div>
                            <div class="right-action added" v-else @click="release(item)">
                                <div class="wavy-line">
                                    <div class="circle-item" v-for="(dotItem, index) in 8" :key="index"></div>
                                </div>
                                <div class="row-item one-row">已发放</div>
                            </div>
                        </div>
                    </template>
                </div>
            </my-popup>
        </div>
    </div>
</template>

<script>
import MyPopup from "./containers/MyPopup";
export default {
    name: "CouponPopup",
    data() {
        return {
            leftIcon: "\ue626",
        };
    },
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default: () => [],
        },
    },
    computed: {},
    components: {
        MyPopup,
    },
    methods: {
        closeCouponPopup() {
            this.$emit("update:isShow", false);
        },
        animateEnd() {
            this.isShow || this.$emit("animateEnd");
        },
        release(item) {
            item.status == 0 && this.$emit("release", { check: "coupon", item });
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.coupon {
    .container {
        .content {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 16px 18px;
            .coupon-item {
                display: flex;
                width: 100%;
                height: 133px;
                margin-bottom: 8px;
                border: 1px solid #fe3266;
                border-left-style: dashed;
                /* border-right: 0; */
                overflow: hidden;
                .left-coupon-size {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 130px;
                    padding: 20px 0;
                    color: #fe3266;
                    font-size: 48px;
                    text-align: center;
                    .discount .decorate {
                        margin-left: 5px;
                    }
                    .decorate {
                        font-size: 16px;
                        font-weight: 700;
                    }
                    .full-reduction {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .decorate {
                            font-size: 18px;
                        }
                    }
                    .amount {
                        font-weight: 700;
                        font-size: 14px;
                    }
                }
                .center-info {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 23px 10px 23px 0;
                    .title-box {
                        display: flex;
                        .decorate {
                            padding: 4px;
                            margin-right: 10px;
                            font-size: 14px;
                            color: #fe3266;
                            font-weight: 700;
                            background-color: #f9d6df;
                            border-radius: 4px;
                        }
                        .title {
                            flex: 1;
                            font-size: 18px;
                            color: #1b162a;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            font-weight: 700;
                        }
                    }
                    .desc-info {
                        font-size: 14px;
                        color: #5c5c6f;
                        font-weight: 700;
                        & .info-item:last-child {
                            margin-top: 5px;
                        }
                    }
                }
                .right-action {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 138px;
                    height: 100%;
                    font-size: 27px;
                    color: #fff;
                    background-image: linear-gradient(179deg, #ff7073, #f63849);
                    cursor: pointer;
                    .mask {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        background-color: transparent;
                        transition: all 0.3s;
                    }
                    &:hover .mask {
                        background-color: rgba(255, 255, 255, 0.3);
                    }
                    .wavy-line {
                        position: absolute;
                        left: -7.5px;
                        .circle-item {
                            width: 15px;
                            height: 15px;
                            margin-top: 5px;
                            border-radius: 50%;
                            background-color: #fff;
                        }
                        .circle-item:first-child {
                            margin-top: 0;
                        }
                    }
                    .row-item {
                        text-align: center;
                        letter-spacing: 5px;
                        transform: translateX(2.5px);
                    }
                }
                .added {
                    background-image: linear-gradient(160deg, #ddd, #aaa);
                    cursor: default;
                }
            }
        }
    }
}
</style>
