import { render, staticRenderFns } from "./LivePauseCountdown.vue?vue&type=template&id=38a1e73a&scoped=true&"
import script from "./LivePauseCountdown.vue?vue&type=script&lang=js&"
export * from "./LivePauseCountdown.vue?vue&type=script&lang=js&"
import style0 from "./LivePauseCountdown.vue?vue&type=style&index=0&id=38a1e73a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a1e73a",
  null
  
)

export default component.exports